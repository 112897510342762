import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';
import routesMap from '../../../Routes';

import PaymentPage from '../../../components/order/PaymentPage';
import callApi from '../../../services/api';
import { getBrowserInfo, registerCard } from '../../../services/mangopay';
import Layout from '../../../layouts/index';
import { pushToLayer } from '../../../services/googleTagManager';

const trackCardSaved = () => {
  pushToLayer({
    event: 'Card Saved',
    context: 'post-order',
  }, true);
};

class PayInContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submitCard = this.submitCard.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    if (window.location.hostname !== 'localhost') {
      window.onbeforeunload = function () {
        return true;
      };
    }
  }

  redirect3DS = (redirectUrl) => {
    if (redirectUrl) {
      window.onbeforeunload = null;
      window.location = redirectUrl;
    } else {
      navigate(routesMap.SuccessPayment.url);
    }
  }

  submitCard(cardData, orderId, customerId) {
    this.setState({ error: false, isLoading: true, errorType: undefined });
    return registerCard(cardData, customerId)
      .then((card) => {
        const BrowserInfo = getBrowserInfo();
        return callApi('public/submitCard', 'post', {
          card, orderId, BrowserInfo, isPayIn: true,
        });
      }).then((res) => {
        trackCardSaved();
        this.redirect3DS(res.redirectUrl);
      })
      .catch((response) => {
        this.setState({ error: true, isLoading: false, errorType: response.error });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  render() {
    const { location } = this.props;
    const { error, errorType, isLoading } = this.state;
    const order = location?.state?.order;
    if (typeof location === 'undefined') return null;
    return (
      <Layout>
        <Router
          location={location}
          key={location.key}
        >
          <PaymentPage
            path="/regler-votre-commande/:orderId/:customerId"
            order={order}
            error={error}
            errorType={errorType}
            isLoading={isLoading}
            submit={this.submitCard}
            isPayIn
          />
        </Router>
      </Layout>
    );
  }
}

PayInContainer.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    state: PropTypes.shape({
      order: PropTypes.shape({}),
    }),
  }),
};

PayInContainer.defaultProps = {
  location: {},
};

export default PayInContainer;
